<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    item: any
    onClose?: Function
    toUrl?: Function
  }>(),
  {},
)

onMounted(() => {
  preventBubbling()
})

function preventBubbling() {
  if (import.meta.env.SSR)
    return
  const msgElem = document.getElementById('msg')
  const aTags = msgElem?.getElementsByTagName('a')

  if (!aTags)
    return

  for (let i = 0; i < aTags.length; i++) {
    aTags[i].addEventListener('click', (event) => {
      event.stopPropagation()
    })
  }
}
</script>

<template>
  <div class="notification">
    <img class="notification-icon" src="@/assets/imgs/notification.svg">
    <div class="content">
      <div id="msg" class="msg" v-html="item.content" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
:global(.update-app-notification.update-app-notification) {
  background-color: #2a47ab;
  border-radius: 10px;
}

.notification {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 10px;
  cursor: pointer;

  .content {
    flex: 1;
    max-width: 100%;
  }

  .notification-icon {
    width: 60px;
    margin-right: 12px;

    @media bp-lt-tablet {
      width: 40px;
    }
  }

  .title {
    margin-bottom: 10px;
    font-size: 20px;
    color: #fff;
  }

  .msg {
    width: 100%;
    max-width: 257px;
    max-height: calc(16px * 10 * 1.23);
    overflow: hidden;
    font-size: 16px;
    color: #fff;
    white-space: pre-wrap;

    @media bp-lt-tablet {
      height: calc(100% / 2 - 50px);
      font-size: 14px;
    }

    :deep(*) {
      overflow: hidden;
      text-overflow: ellipsis;

      img {
        display: none;
      }

      a {
        color: #fff;
        text-decoration: underline;
        text-underline-offset: 4px;
      }
    }
  }
}
</style>
