<script lang="ts">
import { useQuery } from '@tanstack/vue-query'
import { useStorage } from '@vueuse/core'
import { useI18n } from 'vue-i18n'
import { notification } from 'ant-design-vue'
import Notification from './Notification.vue'

enum AnnType {
  IMAGE,
  TEXT,
}

function useAnnouncement(pos: Ref<string>) {
  const { config } = useAppState()
  const annRecordCache = useStorage<Record<string, number>>(`FEEE_FRONTEND_ANNOUNCEMENT`, {})
  const { locale } = useI18n()
  const querier = useQuery({
    queryKey: [apis.articleList.id, locale, pos],
    staleTime: 30 * 60 * 1000,
    queryFn: () =>
      apis.articleList(
        { type: 2, pos: Number(pos.value) },
        { errorMessageMode: 'none' },
      ),
  })

  // 当前显示的公告弹窗信息
  const announcement = computed(() => {
    const _annRecordCache = unref(annRecordCache)
    const _annRecord = unref(querier.data)
    const _locale = unref(locale)
    const _handshake = unref(config)

    if (!_annRecordCache || !_annRecord)
      return

    const annEligible = _annRecord.list?.map((ann) => {
      return {
        ...ann,
        cacheKey: [ann.article_id, ann.modify_time, _locale].join('_'),
        type: ann.is_popup === 1 ? AnnType.IMAGE : ann.is_popup === 2 ? AnnType.TEXT : '',
      }
    })
      .filter((ann) => {
        const isDisplayed = _annRecordCache[ann.cacheKey]
        const canShow = ann.show_position.includes(pos.value) && !isDisplayed && ann.is_popup !== 0

        return canShow
      })[0]

    if (annEligible) {
      return {
        id: annEligible.article_id,
        cacheKey: annEligible.cacheKey,
        content: _locale === 'zh' ? annEligible.summary : annEligible.summary_en,
        title: _locale === 'zh' ? annEligible.title : annEligible.title_en,
        image: (_handshake?.base.cdn_domain ?? '') + (_locale === 'zh' ? annEligible.image : annEligible.image_en),
        url: _locale === 'zh' ? annEligible.url : annEligible.url_en,
        type: annEligible.type,
      }
    }
  })


  // 查看下一个公告
  const next = (cacheKey: string) => {
    if (!cacheKey)
      return
    annRecordCache.value[cacheKey] = new Date().getTime()
  }

  // 跳转到目标URL
  const toURL = (url: string) => {
    if (url)
      window.open(url, '_blank')
  }

  return reactive({ current: announcement, next, toURL })
}
</script>

<script lang="ts" setup>
const props = defineProps<{
  pos: string
}>()

const ann = useAnnouncement(toRef(props, 'pos'))
const { locale } = useI18n()
watch([toRef(ann, 'current'), locale], ([current, oldCurrent],[lang]) => {
  if (current?.id === oldCurrent?.id)
    return

  notification.destroy();
  if (current?.type === AnnType.TEXT) {
    notification.open({
      class: 'announcement-notification',
      description: () => h(Notification, { item: current }),
      onClick() {
        ann.next(current.cacheKey)
        ann.toURL(current.url ?? '')
        notification.close(current.cacheKey)
      },
      onClose() {
        notification.close(current.cacheKey)
        setTimeout(() => ann.next(current.cacheKey), 100)
      },
      message: null,
      key: current.cacheKey,
      duration: 0,
    })
  }
})

</script>

<template>
  <AModal
    v-if="ann.current?.type === AnnType.IMAGE"
    :open="ann.current?.type === AnnType.IMAGE"
    class="announcement-modal"
    :footer="null"
    :closable="false"
  >
    <template #modalRender>
      <div class="wrapper">
        <img
          class="cover-img"
          :class="{ pointer: Boolean(ann.current?.url) }"
          :src="ann.current?.image"
          :alt="ann.current?.title"
          @click="ann.toURL(ann.current?.url ?? '')"
          data-test-id="4rp0"
        >

        <div class="close-btn" @click="ann.next(ann.current?.cacheKey ?? '')" data-test-id="uvz6">
          <i class="icon i-local:close-s1" />
        </div>
      </div>
    </template>
  </AModal>
</template>

<style lang="scss" scoped>
.wrapper {
  pointer-events: all;

  .cover-img {
    display: block;
    max-width: 100%;
    height: auto;
    max-height: calc(100vh - 200px);
    margin: 0 auto;
    color: #fff;

    &.pointer {
      cursor: pointer;
    }
  }

  .close-btn {
    position: relative;
    width: 40px;
    height: 40px;
    margin: 30px auto 0;
    cursor: pointer;

    .icon {
      width: inherit;
      height: inherit;
      color: #fff;
    }
  }
}
</style>

<style lang="scss">
.announcement-modal {
  width: auto !important;
  max-width: fit-content !important;
}

.announcement-notification {
  .ant-notification-notice-message {
    display: none !important;
  }
}
</style>
